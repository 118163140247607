// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contactus-js": () => import("./../../../src/pages/contactus.js" /* webpackChunkName: "component---src-pages-contactus-js" */),
  "component---src-pages-disclaimer-js": () => import("./../../../src/pages/disclaimer.js" /* webpackChunkName: "component---src-pages-disclaimer-js" */),
  "component---src-pages-historical-js": () => import("./../../../src/pages/historical.js" /* webpackChunkName: "component---src-pages-historical-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-methodology-js": () => import("./../../../src/pages/methodology.js" /* webpackChunkName: "component---src-pages-methodology-js" */),
  "component---src-pages-performance-js": () => import("./../../../src/pages/performance.js" /* webpackChunkName: "component---src-pages-performance-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-thisseason-js": () => import("./../../../src/pages/thisseason.js" /* webpackChunkName: "component---src-pages-thisseason-js" */)
}

